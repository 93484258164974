@import "react-slideshow-image/dist/styles.css";

.Header {
  padding: 10px;
  justify-content: space-between;
  display: flex;

  @media (max-width: 640px) {
    display: block;
    text-align: center;
  }
}

.Header-logo {
  height: 40vmin;
  max-height: 60px;

  @media (max-width: 640px) {
    align-items: center;
    max-height: 40px;
  }
}

.Header-list {
  justify-content: right;
  list-style: none;
  display: inline-flex;
  margin: 0;
  align-items: center;

  @media (max-width: 640px) {
    padding: 0;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    margin-top: 10px;
  }
}

.Header-item {
  padding-left: 11px;
  padding-right: 11px;
  align-items: stretch;
  display: inline-flex;
  vertical-align: middle;

  @media (max-width: 640px) {
    width: 30%;
    padding-left: 6px;
    padding-right: 6px;
    display: inline;
  }
}

.Header-item a {
  text-decoration: none;
  align-items: center;
  padding: 0;
  white-space: nowrap;
  color: #666;
  vertical-align: middle;
  font-size: 20px;
}

.About-wrapper {
  padding: 6% 0;
  text-align: center;
  background-color: #082125 !important;
}

.About {
  padding: 0 20%;

  @media (max-width: 1024px) {
    padding: 0 5%;
  }
}

.About h1 {
  font-family: 'Prata', Georgia, "Times New Roman", serif;
  font-size: 3.5em;
  color: #d2d0bb !important;
  line-height: 1.2em;
  margin: 0 0 0.5em 0;

  @media (max-width: 640px) {
    font-size: 2.3em;
  }
}

.About p {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  vertical-align: baseline;
  color: #FFFFFF;
  text-align: center;
  line-height: 1.7em;
}

.About-contacts-wrapper {
  display: inline-block;
  padding: 40px 0;

  @media (max-width: 640px) {
    display: block;
    padding: 20px 0;
  }
}

.About-contact-link {
  text-decoration: none;
  color: #d2d0bb;
  padding: 0 25px;

  @media (max-width: 640px) {
    padding: 15px 0;
    display: block;
  }
}

.About-button-wrapper {
  margin: 40px 0 20px 0;
  align-items: center;
}

.About-button-link {
  color: #000000 !important;
  border-width: 0px !important;
  border-radius: 100px;
  font-size: 16px;
  font-weight: 700 !important;
  background-color: #FFFFFF;
  padding: 15px 30px;
  text-decoration: none;
}

.Benefits-wrapper {
  background-color: #22484f;
  padding: 6% 0;
}

.Benefits {
  padding: 0 20%;

  @media (max-width: 1024px) {
    padding: 0 5%;
  }
}

.Benefits-list {
  list-style: none;
  display: flex;
  justify-content: space-between;
  padding: 0;
  margin: 0;

  @media (max-width: 640px) {
    display: block;
  }
}

.Benefits-item {
  width: 30%;
  text-align: center;

  @media (max-width: 640px) {
    width: 100%;
    padding-bottom: 40px;
  }
}

.Benefits-item:last-child {
  padding-bottom: 0;
}

.Benefits-item h4 {
  font-family: 'Prata', Georgia, "Times New Roman", serif;
  font-size: 22px;
  color: #d2d0bb !important;
  line-height: 1.4em;
  margin: 0 0 15px 0;
}

.Benefits-item p {
  line-height: 1.8em;
  color: #FFFFFF;
}

.Benefits-item-link {
  color: #d2d0bb !important;
  border-width: 0px !important;
  border-radius: 0px;
  font-size: 14px;
  font-weight: 700 !important;
  text-transform: uppercase !important;
  text-decoration: underline !important;
  background-color: RGBA(255, 255, 255, 0);
}

.Gallery-wrapper {
  background-color: #f5ede7;
  padding: 2% 0;
}

.Gallery {
  padding: 0 20%;
  position: relative;

  @media (max-width: 1024px) {
    padding: 0 5%;
  }
}

.Gallery h2 {
  font-family: 'Prata', Georgia, "Times New Roman", serif;
  font-size: 52px;
  line-height: 1.3em;
  color: #333;
  text-align: center;

  @media (max-width: 640px) {
    font-size: 2.3em;
  }
}

.Gallery-list {
  display: inline-flex;
}

.Gallery-item {
  display: inline-flex;
  height: 400px;
  width: auto;

  @media (max-width: 640px) {
    height: auto;
    max-height: 300px;
    margin: auto;
  }
}

.Gallery-photo {
  height: max-content;
  max-height: 400px;
  width: fit-content;
  max-width: 250px;
}

.Contacts-wrapper {
  background-color: #f5ede7;
  font-family: 'Prata', Georgia, "Times New Roman", serif;
  color: #333;
  padding: 2% 0 0 0;
}

.Contacts-header-wrapper {
  padding: 0 0 20px 0;

  @media (max-width: 640px) {
    padding: 0;
  }
}

.Contacts-content-wrapper {
  position: relative;
}

.Contacts h1 {
  margin: 0;
  font-family: 'Prata', Georgia, "Times New Roman", serif;
  font-size: 52px;
  line-height: 1.3em;
  text-align: center;

  @media (max-width: 640px) {
    font-size: 2.3em;
  }
}

.Contacts-map {
  @media (max-width: 640px) {
    display: none;
  }
}

.Contacts-info {
  padding: 4%;
  text-align: left;
  position: absolute;
  background-color: #f5ede7;
  right: 20px;
  bottom: 20px;
  top: 20px;
  max-width: 40%;
  color: #333;
  font-family: 'Prata', Georgia, "Times New Roman", serif;

  @media (max-width: 640px) {
    position: inherit;
    right: 0;
    bottom: 0;
    top: 0;
    max-width: max-content;
  }
}

.Contacts-info h4 {
  font-size: 20px;
  color: #333;

  @media (max-width: 640px) {
    display: none;
  }
}

.Contacts-info-list {

}

.Contacts-info-item {

}

.Contacts-info-link {
  text-decoration: none;
  color: #333;
}

.swiper-pagination {
  position: initial !important;
}

.swiper-pagination-bullet {

}

.swiper-pagination-bullet-active {
  background-color: #22484f !important;
}

.swiper-slide {
  @media (max-width: 640px) {
    width: 100%;
    display: block;
    align-items: center;
  }
}

.swiper-wrapper {
  @media (max-width: 640px) {

  }
}

.swiper-slide-prev,
.swiper-slide-next {
  @media (max-width: 640px) {
    display: none;
  }
}

.swiper-full {
  @media (max-width: 640px) {
    display: none !important;
  }
}

.swiper-mobile {
  display: none !important;

  @media (max-width: 640px) {
    display: block !important;
  }
}